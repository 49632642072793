import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	application: {},
	applications: [],
	isApplicationLoading: { status: false },
	applicationError: {}
});

export default function applicationsReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.APPLICATION_LOADING_SET: {
			return Immutable.set(state, 'isApplicationLoading', payload.isApplicationLoading);
		}
		case actions.APPLICATION_ERROR_SET: {
			return Immutable.set(state, 'applicationError', payload.applicationError);
		}
		case actions.APPLICATION_SET: {
			return Immutable.set(state, 'application', payload.application);
		}
		case actions.APPLICATIONS_SET: {
			return Immutable.set(state, 'applications', payload.applications);
		}
		default: {
			return state;
		}
	}
}
