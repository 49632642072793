import { all, takeLatest, put, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import { UI_ROUTES } from '../../constants/routes';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadProjects(query) {
	const projects = yield call(AuthService.projects, query);
	yield put(actions.setProjects(projects));
}

export default function* projectsSaga() {
	yield all([takeLatest(actions.PROJECTS_LOAD, loadProjects)]);
}
