//import axios from "axios";
import { NotificationManager } from 'react-notifications';

import { API_ROUTES } from '../constants/routes';
import { NotificationUtils } from '../utils/NotificationUtils';
import { BaseService } from './BaseService';
import StorageUtils from '../utils/StorageUtils';

// import mockBranches from '../utils/mocks/branches';
// import { mockProjectsSupported } from '../utils/mocks/projectsSupported'

import { static_page_data as staticPageData } from './StaticPageData';

const ERRORS_MAP = {
	'The email has already been taken.': 'Diese E-Mail-Adresse ist bereits vergeben',
};

//const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class AuthService extends BaseService {
	constructor() {
		super(API_ROUTES.root);
	}

	reset = async (credentials) => {
		const { agent } = this;
		try {
			// const { data } = await agent.post(API_ROUTES.reset, email);
			// const instance = axios.create({ baseURL: 'https://virtserver.swaggerhub.com/WirWunder/ApiDb/0.1' });
			// const instance = axios.create({ baseURL: 'http://46.101.211.146:8080' });
			// instance.interceptors.response.use(
			// 	res => res || { success: true, data: {} },
			// 	err => {
			// 		const { config, status, response } = err;

			// 		const statusCode = status || (response && response.status);
			// 		const isLogin = config && config.url && config.url.includes(API_ROUTES.login);

			// 		const errorDivEmail = document.querySelector('#reset-form input[name="email"] ~ div.form-error');

			// 		if (statusCode && statusCode === 401) {
			// 			if (!isLogin) {
			// 				NotificationUtils.throwUnauthorized();
			// 				return;
			// 			}
			// 		}

			// 		if (statusCode && statusCode === 400) {
			// 			// const { message } = response.data;
			// 			errorDivEmail.innerHTML = 'Zu dieser E-Mail-Adresse wurde kein Nutzerkonto gefunden.';

			// 			errorDivEmail.classList.add('visible');
			// 			setTimeout(() => {
			// 				errorDivEmail.classList.remove('visible');
			// 			}, 3000);
			// 		}

			// 		let { message } = err;
			// 		if (response && response.data && response.data.error) {
			// 			message = response.data.error;
			// 		}
			// 		err.errorText = message;

			// 		throw err;
			// 	},
			// );

			const { data } = await agent.post(
				`/v1.0/api${API_ROUTES.reset}`,
				credentials,
			);
			NotificationManager.success(
				'Bitte prüfe deinen E-Mail-Eingang.',
				'Informationen [200]',
				7000,
			);
			return { data };
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			const message = err && err.response && err.response.data
				? err.response.data.message
				: null;
			if (message === 'Invalid email supplied') {
				err.response.data.message = 'Zu dieser E-Mail-Adresse wurde kein Nutzerkonto gefunden.';
			}
			NotificationManager.error(
				`${err.response.data.message}`,
				`Fehler [${err.response.status}]`,
				7000,
			);
			return null;
		}
	};

	reset2 = async (credentials) => {
		const { agent } = this;
		try {
			// agent.interceptors.response.use(
			// 	res => res || { success: true, data: {} },
			// 	err => {
			// 		const { config, status, response } = err;

			// 		const statusCode = status || (response && response.status);
			// 		const isLogin = config && config.url && config.url.includes(API_ROUTES.login);

			// 		if (statusCode && statusCode === 401) {
			// 			if (!isLogin) {
			// 				NotificationUtils.throwUnauthorized();
			// 				return;
			// 			}
			// 		}

			// 		const errorDivpasswordConfirmation = document.querySelector(
			// 			'#reset-form input[name="passwordConfirmation"] ~ div.form-error',
			// 		);

			// 		if (statusCode && (statusCode === 400 || statusCode === 403)) {
			// 			const { message } = response.data;
			// 			errorDivpasswordConfirmation.innerHTML = message;

			// 			errorDivpasswordConfirmation.classList.add('visible');
			// 			setTimeout(() => {
			// 				errorDivpasswordConfirmation.classList.remove('visible');
			// 			}, 3000);
			// 		}

			// 		let { message } = err;
			// 		if (response && response.data && response.data.error) {
			// 			message = response.data.error;
			// 		}
			// 		err.errorText = message;

			// 		throw err;
			// 	},
			// );
			const { data } = await agent.post(
				`/v1.0/api${API_ROUTES.reset2}`,
				credentials,
			);
			NotificationManager.success(
				'Please login with new password',
				'Informationen [200]',
				7000,
			);
			return { data };
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			NotificationManager.error('Invalid token. Try again', 'Fehler', 7000);
			return null;
		}
	};

	confirm = async (key) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(`/v1.0/api/registration/confirm/${key}`);
			return { data };
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	update = async (credentials, userId) => {
		const { agent } = this;
		try {
			const { data } = await agent.put(
				`/v1.0/api/user/${userId}`,
				credentials,
			);
			return { data };
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			NotificationManager.warning(
				`${err.response.data.message} [${err.response.status}]`,
			);
			return null;
		}
	};

	changeEmail = async credentials => {
		try {
			const { agent } = this;
			return await agent.post('/v1.0/api/user/changeEmail', credentials);
		} catch (err) {
			return err;
		}
	};

	confirmEmail = async credentials => {
		try {
			const { agent } = this;
			return await agent.post('/v1.0/api/user/setEmail', credentials);
		} catch (err) {
			return err;
		}
	};

	profile = async () => {
		const { agent } = this;
		agent.interceptors.request.use((config) => {
			const token = StorageUtils.restoreToken();
			config = {
				...config,
				headers: {
					...config.headers,
					Authorization: `Bearer ${token}`,
				},
			};

			return config;
		});
		try {
			const { data } = await agent.get('v1.0/api/user');
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	login = async (credentials) => {
		const errorDivPassword = document.querySelector(
			'#login-form input[name="password"] ~ div.form-error',
		);
		const { agent } = this;
		try {
			agent.interceptors.response.use(
				(res) => res || { success: true, data: {} },
				(err) => {
					const { status, response } = err;

					const statusCode = status || (response && response.status);

					const errorDivEmail = document.querySelector(
						'#login-form input[name="email"] ~ div.form-error',
					);
					const errorDivPassword = document.querySelector(
						'#login-form input[name="password"] ~ div.form-error',
					);
					const submitButton = document.querySelector('button.btn-login');

					if (statusCode && statusCode === 401) {
						return;
					}

					if (statusCode && statusCode === 403) {
						const { message } = response.data;
						//const message = 'You tried 5 times with wrong credential please try again in 2 minutes';
						let minute;
						try {
							[, minute] = [...message.match(/\d+/g)];
						} catch {
							minute = 1;
						}

						if (minute < 1) {
							minute = 1;
						}

						let seconds = minute * 60;
						let minuteDigit = parseInt(seconds / 60, 10);
						if (minuteDigit < 10) {
							minuteDigit = `0${minuteDigit}`;
						}

						let secondDigit = parseInt(seconds % 60, 10);
						if (secondDigit < 10) {
							secondDigit = `0${secondDigit}`;
						}

						const messageDe = `Sie haben 5 mal ein falsches Passwort eingegeben. Bitte versuchen Sie es in <span id='timerLogin'>${minuteDigit}:${secondDigit}</span> Minuten erneut`;

						errorDivPassword.classList.add('visible');
						errorDivPassword.innerHTML = messageDe;
						submitButton.disabled = true;

						const timerLogin = errorDivPassword.querySelector('#timerLogin');
						const timerMinute = setInterval(() => {
							if (seconds < 1) {
								clearInterval(timerMinute);
								errorDivPassword.classList.remove('visible');
								submitButton.disabled = false;
								return;
							}

							seconds--;

							let minuteDigit = parseInt(seconds / 60, 10);
							if (minuteDigit < 10) {
								minuteDigit = `0${minuteDigit}`;
							}

							let secondDigit = parseInt(seconds % 60, 10);
							if (secondDigit < 10) {
								secondDigit = `0${secondDigit}`;
							}

							timerLogin.innerHTML = `${minuteDigit}:${secondDigit}`;
						}, 1000);
					}

					if (
						((statusCode && statusCode === 500)
							|| (statusCode && statusCode === 400)
						) && errorDivPassword
					) {
						errorDivPassword.classList.add('visible');

						errorDivPassword.innerHTML = 'E-Mail-Adresse oder Passwort nicht korrekt.';

						errorDivEmail.parentElement.addEventListener('keydown', () => {
							errorDivPassword.classList.remove('visible');
						});

						errorDivPassword.parentElement.addEventListener('keydown', () => {
							errorDivPassword.classList.remove('visible');
						});
					}

					let { message } = err;
					if (response && response.data && response.data.error) {
						message = response.data.error;
					}
					err.errorText = message;

					throw err;
				},
			);

			const result = await agent.post('/v1.0/api/user/login', credentials);
			const accessToken = result?.data?.access_token
			if (!accessToken) {
				const messageDe = 'E-Mail-Adresse oder Passwort nicht korrekt. Bitte gib eine korrekte Kombination ein. Wenn du dein Passwort vergessen hast, klicke bitte auf "Passwort vergessen"';
				errorDivPassword.classList.add('visible');
				errorDivPassword.innerHTML = messageDe;
				return;
			}
			return accessToken;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	logout = async () => {
		const { agent } = this;
		const token = StorageUtils.restoreToken();
		agent.interceptors.request.use((config) => {
			config = {
				...config,
				headers: {
					...config.headers,
					Authorization: `Bearer ${token}`,
				},
			};

			return config;
		});
		try {
			if (token) {
				const { data } = await agent.post('/v1.0/api/user/logout');
				return data;
			}
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	register = async (credentials) => {
		const { agent } = this;
		try {
			agent.interceptors.response.use(
				(res) => res || { success: true, data: {} },
				(err) => {
					const { config, status, response } = err;

					const statusCode = status || (response && response.status);
					const isLogin = config && config.url && config.url.includes(API_ROUTES.login);

					if (statusCode && statusCode === 401) {
						if (!isLogin) {
							NotificationUtils.throwUnauthorized();
							return;
						}
					}

					if (statusCode && statusCode === 422 && response.data.errors) {
						Object.keys(response.data.errors).forEach(key => {
							const errorDiv = document.querySelector(
								`#register-form input[name="${key}"] ~ div.form-error`,
							);
							errorDiv.classList.add('visible');
							errorDiv.innerHTML = response.data.errors[key].map(e => ERRORS_MAP[e] || e).join(', ');
							errorDiv.parentElement.addEventListener('keydown', () => errorDiv.classList.remove('visible'));
						});
					}

					let { message } = err;
					if (response && response.data && response.data.error) {
						message = response.data.error;
					}
					err.errorText = message;

					throw err;
				},
			);

			const { data } = await agent.post('/v1.0/api/user', credentials);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	partners = async () => {
		const { agent } = this;
		try {
			const { data } = await agent.get('v1.0/api/partners');
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	getStaticPagesOfBranchBackOffice = async (id) => {
		const { agentBackOffice } = this;
		try {
			const { data } = await agentBackOffice.get(
				`v1.0/api/default-static-pages?branch_id=${id}`,
			);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	partnersBackOffice = async (id) => {
		const { agentBackOffice } = this;
		try {
			const { data } = await agentBackOffice.get(
				`v1.0/api/partners?branch_id=${id}`,
			);
			// const data = [
			// 	{
			// 		id: 1,
			// 		name:
			// 			'wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw',
			// 		description:
			// 			'wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw',
			// 		website: 'https://www.google.de/',
			// 		logo: 'http://ww-backend.tableofvisions.com/images/noimage/partner.png',
			// 		active: true,
			// 	},
			// 	{
			// 		id: 6,
			// 		name:
			// 			'wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw',
			// 		description:
			// 			'wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqwwqeqweqweqw wqeqweqweqwwqeqweqweqwwqeqweqweqw',
			// 		website: 'http://ww-backend.tableofvisions.com/',
			// 		logo: 'http://ww-backend.tableofvisions.com/images/noimage/partner.png',
			// 		active: true,
			// 	},
			// 	{
			// 		id: 3,
			// 		name: 'Test Simon',
			// 		description: 'kcdcccccccccccccccccurtz dcddddddddddddddc  cdcfdfff',
			// 		website: 'test',
			// 		logo: 'http://ww-backend.tableofvisions.com/images/noimage/partner.png',
			// 		active: true,
			// 	},
			// ];
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	branchNewsBackOffice = async (id) => {
		const { agentBackOffice } = this;
		try {
			const response = await agentBackOffice.get(
				`v1.0/api/news?branch_id=${id}`,
			);
			// const response = {
			//   status: 200,
			//   data: [
			//     {
			//       headline: "1 -- Jetzt 555 Euro für deine Ideegewinnen!",
			//       text:
			//         "WirWunder ist das Portal fürt und Betterplace. Ssoziale, ökologische oder kreative Projekte.",
			//       image:
			//         "https://top10.travel/wp-content/uploads/2014/09/brandenburgskie-vorota-1.jpg",
			//       link:
			//         "https://top10.travel/wp-content/uploads/2014/09/brandenburgskie-vorota-1.jpg",
			//     },
			//     {
			//       headline: "2 -- Id anim veniam mollit incididunt cillum magna.",
			//       text:
			//         "WirWunder ist das Portal für gesellschaftliches Engagement in Kooperation mit Startnext und Betterplace. Hier findest du kulturelle, soziale, ökologische oder kreative Projekte.",
			//       image:
			//         "https://avatarko.ru/img/kartinka/33/multfilm_lyagushka_32117.jpg",
			//       link: "#",
			//     },
			//     {
			//       headline: "3 -- Exercitation eu sit consectetur nostrud laboris.",
			//       text:
			//         "Aliqua exercitation dolore culpa ad. Enim tempor labore consequat do mollit ipsum adipisicing Lorem occaecat ea velit. Culpa ad commodo eu et. Consectetur velit officia dolore exercitation laborum labore exercitation ullamco adipisicing. WirWunder ist das Portal für gesellschaftliches Engagement in Kooperation mit Startnext und Betterplace. Hier findest du kulturelle, soziale, ökologische oder kreative Projekte.",
			//       image: "https://avatarko.ru/img/kartinka/11/film_Avatar_10515.jpg",
			//       link: "#",
			//     },
			//     {
			//       headline:
			//         "4 -- Consectetur magna excepteur eiusmod eiusmod occaecat.",
			//       text:
			//         "WirWunder ist das Portal für gesellschaftliches Engagement in Kooperation mit Startnext und Betterplace. Hier findest du kulturelle, soziale, ökologische oder kreative Projekte.",
			//       image:
			//         "https://i.pinimg.com/originals/c0/b7/7f/c0b77faeb2cb3e67fd1b423c4535f6c3.jpg",
			//       link: "#",
			//     },
			//   ],
			// };

			return response;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	staticPagesBackOffice = async () => {
		const { agentBackOffice } = this;

		try {
			const response = await agentBackOffice.get(
				'/v1.0/api/default-static-pages',
			);
			const { data } = response;

			return data;

		} catch (err) {
			return staticPageData;
			//NotificationUtils.error(err.errorText || err.message);
			// return [];
			// return [
			// 	{
			// 		id: 7,
			// 		alias: 'agb',
			// 		title: 'AGB',
			// 		content:
			// 			' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque bibendum feugiat diam, id faucibus mi tempor nec. Duis in metus eu nisl hendrerit imperdiet. Nullam risus odio, convallis sit amet nisi at, hendrerit tempor erat. Pellentesque lobortis, sem quis auctor eleifend, sem justo volutpat elit, eu lacinia orci nibh gravida nisi. Nam facilisis urna massa, vitae blandit nisi pretium at. Donec purus purus, pharetra et tortor sed, lobortis commodo leo. In feugiat, ex vel rhoncus efficitur, odio dui sodales eros, et eleifend tortor justo quis justo. Fusce vulputate malesuada odio. Interdum et malesuada fames ac ante ipsum primis in faucibus. Cras non mi lorem. Fusce lorem lacus, ornare in lacinia ac, rhoncus quis augue. Fusce lobortis nisi at lacus euismod, ac condimentum augue vestibulum. Maecenas ut pulvinar turpis.',
			// 	},
			// 	{
			// 		id: 5,
			// 		alias: 'cookie',
			// 		title: 'Cookie-Banner',
			// 		content:
			// 			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque bibendum feugiat diam, id faucibus mi tempor nec. Duis in metus eu nisl hendrerit imperdiet. Nullam risus odio, convallis sit amet nisi at, hendrerit tempor erat. Pellentesque lobortis, sem quis auctor eleifend, sem justo volutpat elit, eu lacinia orci nibh gravida nisi. Nam facilisis urna massa, vitae blandit nisi pretium at. Donec purus purus, pharetra et tortor sed, lobortis commodo leo. In feugiat, ex vel rhoncus efficitur, odio dui sodales eros, et eleifend tortor justo quis justo. Fusce vulputate malesuada odio. Interdum et malesuada fames ac ante ipsum primis in faucibus. Cras non mi lorem. Fusce lorem lacus, ornare in lacinia ac, rhoncus quis augue. Fusce lobortis nisi at lacus euismod, ac condimentum augue vestibulum. Maecenas ut pulvinar turpis.',
			// 	},
			// ];
		}
	};

	faqBackOffice = async () => {
		const { agentBackOffice } = this;

		try {
			const response = await agentBackOffice.get('v1.0/api/faq');
			const { data } = response;

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return [];
			// return [
			// 	{
			// 		category_id: 1,
			// 		category_title: 'Category 1',
			// 		faq_answers: [
			// 			{
			// 				id: 1,
			// 				category_id: 1,
			// 				question: 'Test question 1',
			// 				answer: 'Test answer 1',
			// 				order_pos: 1,
			// 			},
			// 			{
			// 				id: 2,
			// 				category_id: 1,
			// 				question: 'Test question 2',
			// 				answer: 'Test answer 2',
			// 				order_pos: 2,
			// 			},
			// 		],
			// 	},
			// 	{
			// 		category_id: 2,
			// 		category_title: 'Category 2',
			// 		faq_answers: [
			// 			{
			// 				id: 1,
			// 				category_id: 2,
			// 				question: 'Test question 1',
			// 				answer: 'Test answer 1',
			// 				order_pos: 1,
			// 			},
			// 			{
			// 				id: 2,
			// 				category_id: 2,
			// 				question: 'Test question 2',
			// 				answer: 'Test answer 2',
			// 				order_pos: 2,
			// 			},
			// 		],
			// 	},
			// ];
		}
	};

	projectsSupportedBackOffice = async (payload) => {
		const { id, count, page, request_type: requestType } = payload;
		const { agentBackOffice } = this;
		try {
			const apiPath = `v1.0/api/organization-projects?page=${page}&count=${count}&branch_id=${id}&funding_request_type=${requestType}`;
			const { data } = await agentBackOffice.get(apiPath);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projectsSupportedSponsoring = async (payload) => {
		const { id, count, page, request_type: requestType } = payload;
		const { agentBackOffice } = this;
		try {
			const apiPath = `v1.0/api/organization-projects?page=${page}&count=${count}&branch_id=${id}&funding_request_type=${requestType}`;
			const { data } = await agentBackOffice.get(apiPath);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projectsSupportedFoundation = async (payload) => {
		const { id, count, page, request_type: requestType } = payload;
		const { agentBackOffice } = this;
		try {
			const apiPath = `v1.0/api/organization-projects?page=${page}&count=${count}&branch_id=${id}&funding_request_type=${requestType}`;
			const { data } = await agentBackOffice.get(apiPath);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projectsSupportedImportedBackOffice = async (payload) => {
		const { id, count, page } = payload;
		const { agentBackOffice } = this;
		try {
			const apiPathImported = `v1.0/api/organization-projects-imported?page=${page}&count=${count}&branch_id=${id}`;
			const { data } = await agentBackOffice.get(apiPathImported);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	allProjectsSupportedBackOffice = async (payload) => {
		const { id, count, page } = payload;
		const { agentBackOffice } = this;
		try {
			const apiPath = `v1.0/api/ps-projects?page=${page}&count=${count}&branch_id=${id}`;
			const { data } = await agentBackOffice.get(apiPath);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projects = async (query) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(
				`/v1.0/api/projects${query}&member_state=confirmed&rank_sort=true`,
			);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projectById = async (id) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(`/v1.0/api/projects/${id}`);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	projectsByFilter = async (query, text) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(
				`/v1.0/api/projects/findByFilter?${query}&member_state=confirmed&rank_sort=true`,
				{ params: { text } },
			);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	kpi = async () => {
		const { agent } = this;
		try {
			const { data } = await agent.get('/v1.0/api/kpi');
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	kpiBo = async () => {
		const { agentBackOffice } = this;
		try {
			const { data } = await agentBackOffice.get('/v1.0/api/kpi');
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	kpiByBranch = async (id) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(`/v1.0/api/kpi/findByBranch?id=${id}`);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	kpiByBranchBo = async (id) => {
		const { agentBackOffice } = this;
		try {
			const { data } = await agentBackOffice.get(`/v1.0/api/kpi/findByBranch?id=${id}`);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	kpiSettings = async (id) => {
		const { agent } = this;
		try {
			const { data } = await agent.get(`/v1.0/api/branch-kpi-settings/${id}`);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	branches = async () => {
		const { agent } = this;
		try {
			//return mockBranches;
			const { data } = await agent.get('/v1.0/api/branches');
			return { data };
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	contacts = async (id) => {
		const { agentBackOffice } = this;
		try {
			const { data } = await agentBackOffice.get(
				`/v1.0/api/contact-persons?branch_id=${id}`,
			);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			// return [
			//   {
			//     id: 16,
			//     name: "Max Mustermann",
			//     function: "Sponsoring",
			//     email: "test@test.de",
			//     phone: "123423123",
			//     avatar:
			//       "https://stage-admin-bereich.wirwunder.de/instance/contacts/16/160_1598546787_5f47e36332dd88_64126495.png",
			//   },
			// ];
			return null;
		}
	};

	getBranchHeaderImage = async (id) => {
		const { agent } = this;
		try {
			//return mockPicture;
			const { data } = await agent.get(`/v1.0/api/branches/${id}/header`);
			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return { success: false, data: { image: null } };
		}
	};

	getSearchedBranchesImages = async (branchesArr) => {
		try {
			return await Promise.all(
				branchesArr.map(async (branch) => {
					const response = await this.getBranchHeaderImage(branch.id);

					if (response.data) {
						const {
							data: { branch_id, image },
							success,
						} = response;

						return { data: { success, branch_id, image } };
					}

					return { data: { success: true, branch_id: null, image: null } };
				}),
			);
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return { data: { success: false, image: null, branch_id: null } };
		}
	};

	changePassword = async credentials => {
		try {
			const { agent } = this;
			const { data } = await agent.post('/v1.0/api/user/changePassword', credentials);
			return data;
		} catch (err) {
			return err;
		}
	};

	deleteProfile = async credentials => {
		const { agent } = this;
		let accessToken = '';
		try {
			agent.interceptors.response.use(
				(res) => res || { success: true, data: {} },
				(err) => ({ success: false, error: err.response.data.error })
			);

			const { data } = await agent.post('/v1.0/api/user/login', credentials);

			if (data?.access_token) {
				accessToken = data.access_token;
			}
			if (!accessToken) {
				return { success: false, passwordError: 'Passwort ist nicht korrekt. Bitte versuche es erneut' };
			}
		} catch (err) {
			NotificationUtils.error(err?.errorText || err?.message);
			return { success: false, error: 'Etwas ist schief gelaufen' };
		}

		try {
			agent.interceptors.request.use((config) => {
				config = {
					...config,
					headers: {
						...config.headers,
						Authorization: `Bearer ${accessToken}`,
					},
				};

				return config;
			});
			const result = await agent.delete('/v1.0/api/user');
			if (result?.success || result?.data?.success) return { success: true };

			return { success: false, error: 'Etwas ist schief gelaufen' };
		} catch (err) {
			NotificationUtils.error(err?.errorText || err?.message);
			return { success: false, error: 'Etwas ist schief gelaufen' };
		}
	};

	getBranchNavigationSettings = async (branchId) => {
		const { agent } = this;

		try {
			const url = `v1.0/api/branch-navigation-settings/${branchId}`;
			const { data } = await agent.get(url);

			return data;
		} catch (err) {
			NotificationUtils.error(err.errorText || err.message);
			return null;
		}
	};

	getZweckertrageSettings = async branchId => {
		// const mockedSettings = {
		//   "branch_id": 246,
		//   "image": "http://ww-backend.tableofvisions.com/instance/branch-ps-settings/1/280_1611754040_60116a38d7f163_73155894.jpg",
		//   "title": "",
		//   "slogan": "Wenn Sie dieses Feld leer lassen, wird standardmäßig folgender Text angezeigt:"
		// }
		// return Promise.resolve(mockedSettings);
		try {
			const { agentBackOffice } = this;
			const { data } = await agentBackOffice.get(`/v1.0/api/ps-zweckertrage-settings?branch_id=${branchId}`);
			return data;
		} catch (err) {
			return {};
		}
	};

	getStiftungSettings = async branchId => {
		try {
			const { agentBackOffice } = this;
			const { data } = await agentBackOffice.get(`/v1.0/api/stiftung-settings?branch_id=${branchId}`);
			return data;
		} catch (err) {
			return {};
		}
	};

	getSupportedProject = async (id, isImported, isPartnerProject, requestId) => {
		const { agentBackOffice } = this;
		const organizationUrl = `/v1.0/api/organization-projects${isImported ? '-imported' : ''}/${id}`;
		const partnerUrl = `/v1.0/api/partner-projects/${id}/${requestId}`;
		const url = isPartnerProject ? partnerUrl : organizationUrl;
		const { data } = await agentBackOffice.get(url);
		return data;
	};

	getCampaignsProject = async id => {
		try {
			const { agentBackOffice } = this;
			const voucherResponse = await agentBackOffice.get(`/v1.0/api/campaigns?branch_id=${id}&type=voucher`);
			const nonVoucherResponse = await agentBackOffice.get(`/v1.0/api/campaigns?branch_id=${id}&type=doubling`);

			return [...voucherResponse.data, ...nonVoucherResponse.data];
		} catch (err) {
			return err;
		}
	};

	getCampaign = async id => {
		try {
			const { agentBackOffice } = this;
			const campaignResponse = await agentBackOffice.get(`/v1.0/api/campaigns/${id}`);

			return campaignResponse.data;
		} catch (error) {
			return { error };
		}
	};

	getProjects = async (ids, count = 6, page = '?page=1') => {
		try {
			const { agent } = this;
			const url = `/v1.0/api/projects${page}&count=${count}&partner_external_id=${ids.join()}`;
			const { data } = await agent.get(url);
			return data;
		} catch (err) {
			return err;
		}
	};

	checkIsVoucherCampaign = async (projectId) => {
		try {
			const { agentBackOffice } = this;
			const response = await agentBackOffice.post(
				'/v1.0/api/campaigns/projects/check-active-voucher',
				JSON.stringify({ project_id: projectId }),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);

			if (response.status === 200) {
				return (
					{ success: true, redeemedVouchersAmount: response.data.used_vouchers_amount_for_campaigns }
				);
			}

			return { success: false };
		} catch (err) {
			if (err.response?.status !== 400) {
				console.log('voucher request error', err);
			}

			return { success: false };
		}
	};

	redeemVouchercode = async (code, projectId) => {
		try {
			const { agentBackOffice } = this;
			const response = await agentBackOffice.post(
				'/v1.0/api/voucher/redeem',
				JSON.stringify({ code, project_id: projectId }),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);

			if (response.status === 200) {
				return { success: true, data: response.data };
			}

			return { error: true };
		} catch (err) {
			return { error: true };
		}

	};
}

const Service = new AuthService();

export default Service;
export { Service as AuthService };
