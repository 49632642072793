import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[BasicAuth]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	HTACCESS: `${prefix} htaccess`
};

const actions = {
	...TYPES,
	setHtaccess: makeActionCreator(TYPES.HTACCESS)
};

export default actions;
export { actions as basicAuthAction };
