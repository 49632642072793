import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { UI_ROUTES } from '../constants/routes';

const Login = lazy(() => import('../pages/Login'));
const LoginSparkasse = lazy(() => import('../pages/LoginSparkasse'));
const Start = lazy(() => import('../pages/Start'));
const Page404 = lazy(() => import('../pages/Page404'));
const SocialBots = lazy(() => import('../pages/SocialBots'));
const Branches = lazy(() => import('../pages/Branches'));
const RegComplete = lazy(() => import('../pages/RegComplete'));
const Branch = lazy(() => import('../pages/Branch'));
const BranchInfo = lazy(() => import('../pages/BranchInfo/index'));
const WirwunderOnlineForm = lazy(() => import('../pages/WirwunderOnlineForm'));
const BranchByIdRedirect = lazy(() => import('../pages/BranchByIdRedirect/index'));
const ProjectsSupported = lazy(() => import('../pages/ProjectsSupported'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Kontakt = lazy(() => import('../pages/Kontakt/index'));
const UserProfilePage = lazy(() => import('../pages/UserProfile/index'));
const Faq = lazy(() => import('../pages/Faq/index'));
const StaticPages = lazy(() => import('../pages/StaticPages/index'));
const ActionDetails = lazy(() => import('../pages/ActionDetails/index'));
const FaqPage = lazy(() => import('../pages/Faq/index'));
const TotalFinanced = lazy(() => import('../pages/TotalFinanced'));
const RequestRelease = lazy(() => import('../pages/RequestRelease'));

const RestrictedRoutes = () => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={`${UI_ROUTES.root}`}
          render={(props) => <Start {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.root}Projekte-entdecken`}
          render={(props) => <Start {...props} />}
        />
        <Route
          exact
          path={UI_ROUTES.userProfile}
          render={(props) => <UserProfilePage {...props} />}
        />
        <Route
          exact
          path={[
            `${UI_ROUTES.wasIstWirWunder}`,
            `${UI_ROUTES.footer11}`,
            `${UI_ROUTES.footer12}`,
            `${UI_ROUTES.footer31}`,
            `${UI_ROUTES.footer32}`,
            `${UI_ROUTES.footer33}`,
            `${UI_ROUTES.footer34}`
          ]}
          render={(props) => <StaticPages {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.footer13}`}
          render={(props) => <FaqPage {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.root}:name?${UI_ROUTES.kontakt}`}
          render={(props) => <Kontakt {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.requestRelease}/:hash`}
          render={(props) => <RequestRelease {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.root}:name?${UI_ROUTES.totalFinanced}`}
          render={(props) => <TotalFinanced {...props} />}
        />
        <Route
          path={`${UI_ROUTES.regionalportal}:name/projekt-starten`}
          render={(props) => <BranchInfo {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.regionalportal}:name${UI_ROUTES.onlineForm}/:id?/step/:step`}
          render={(props) => <WirwunderOnlineForm {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.root}:name?${UI_ROUTES.footer13}`}
          render={(props) => <Faq {...props} />}
        />

        <Route
          path={`${UI_ROUTES.start}:filter?`}
          render={(props) => <Branches {...props} />}
        />
        <Route
          exact
          path={UI_ROUTES.register}
          render={(props) => <Login {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.resetpassword}/:key?`}
          render={(props) => <ResetPassword {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.root}:name?${UI_ROUTES.resetpassword}/:key?`}
          render={(props) => <ResetPassword {...props} />}
        />
        <Route
          exact
          path={UI_ROUTES.complete}
          render={(props) => <RegComplete already {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.complete}/:key`}
          render={(props) => <RegComplete {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.projects}:id`}
          render={(props) => <SocialBots {...props} />}
        />
        <Route
          exact
          path={UI_ROUTES.authinspark}
          render={(props) => <LoginSparkasse {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.regionalportalId}:id`}
          render={(props) => <BranchByIdRedirect {...props} />}
        />

        <Route
          exact
          path={`${UI_ROUTES.regionalportal}:name`}
          render={(props) => <Branch {...props} />}
        />

        <Route
          exact
          path={`${UI_ROUTES.regionalportal}:name/aktion/:id`}
          render={(props) => <ActionDetails {...props} />}
        />

        <Route
          exact
          path={`${UI_ROUTES.regionalportal}:name/foerderung/:id`}
          render={(props) => <ProjectsSupported {...props} />}
        />
        <Route
          exact
          path={`${UI_ROUTES.regionalportal}:name/foerderung/egm/:id`}
          render={(props) => <ProjectsSupported {...props} isImported />}
        />

        <Route
          path={`${UI_ROUTES.regionalportal}:name/Projekte-entdecken`}
          render={(props) => <Branch {...props} />}
        />

        <Route
          exact
          path={`${UI_ROUTES.root}:name?${UI_ROUTES.footer13}`}
          render={(props) => <FaqPage {...props} />}
        />

        <Route
          exact
          path={[
            `${UI_ROUTES.customStaticPage}/:alias`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.wasIstWirWunder}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer11}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer12}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer31}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer32}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer33}`,
            `${UI_ROUTES.root}:name?${UI_ROUTES.footer34}`,
            `${UI_ROUTES.root}:name/:alias`
          ]}
          render={(props) => <StaticPages {...props} />}
        />
        <Route
          path={UI_ROUTES.page404}
          render={(props) => <Page404 {...props} />}
        />
        <Route path='*' render={(props) => <Page404 {...props} />} />
      </Switch>
    </Suspense>
  );
};

export default RestrictedRoutes;
export { RestrictedRoutes };
