import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	requestRelease: {},
	isRequestReleaseLoading: { status: true },
	isRequestReleaseCreating: { status: false },
	requestReleaseError: {}
});

export default function requestReleaseReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.REQUEST_RELEASE_LOADING_SET: {
			return Immutable.set(state, 'isRequestReleaseLoading', payload.isRequestReleaseLoading);
		}
		case actions.REQUEST_RELEASE_CREATING_SET: {
			return Immutable.set(state, 'isRequestReleaseCreating', payload.isRequestReleaseCreating);
		}
		case actions.REQUEST_RELEASE_ERROR_SET: {
			return Immutable.set(state, 'requestReleaseError', payload.requestReleaseError);
		}
		case actions.REQUEST_RELEASE_SET: {
			return Immutable.set(state, 'requestRelease', payload.requestRelease);
		}
		default: {
			return state;
		}
	}
}
