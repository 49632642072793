export const DETAIL_PURPUSE = [
	{ id: '0', name: '1. die Förderung von Wissenschaft und Forschung' },
	{ id: '1', name: '2. die Förderung der Religion' },
	{ id: '2', name: '3. die Förderung des öffentlichen Gesundheitswesens und der öffentlichen Gesundheitspflege, insbesondere die Verhütung und Bekämpfung von übertragbaren Krankheiten, auch durch Krankenhäuser im Sinne des § 67, und von Tierseuchen' },
	{ id: '3', name: '4. die Förderung der Jugend- und Altenhilfe' },
	{ id: '4', name: '5. die Förderung von Kunst und Kultur' },
	{ id: '5', name: '6. die Förderung des Denkmalschutzes und der Denkmalpflege' },
	{ id: '6', name: '7. die Förderung der Erziehung, Volks- und Berufsbildung einschließlich der Studentenhilfe' },
	{ id: '7', name: '8. die Förderung des Naturschutzes und der Landschaftspflege im Sinne des Bundesnaturschutzgesetzes und der Naturschutzgesetze der Länder, des Umweltschutzes, des Küstenschutzes und des Hochwasserschutzes' },
	{ id: '8', name: '9. die Förderung des Wohlfahrtswesens, insbesondere der Zwecke der amtlich anerkannten Verbände der freien Wohlfahrtspflege (§ 23 der Umsatzsteuer-Durchführungsverordnung), ihrer Unterverbände und ihrer angeschlossenen Einrichtungen und Anstalten' },
	{ id: '9', name: '10. die Förderung der Hilfe für politisch, rassisch oder religiös Verfolgte, für Flüchtlinge, Vertriebene, Aussiedler, Spätaussiedler, Kriegsopfer, Kriegshinterbliebene, Kriegsbeschädigte und Kriegsgefangene, Zivilbeschädigte und Behinderte sowie Hilfe für Opfer von Straftaten; Förderung des Andenkens an Verfolgte, Kriegs- und Katastrophenopfer; Förderung des Suchdienstes für Vermisste' },
	{ id: '10', name: '11. die Förderung der Rettung aus Lebensgefahr' },
	{ id: '11', name: '12. die Förderung des Feuer-, Arbeits-, Katastrophen- und Zivilschutzes sowie der Unfallverhütung' },
	{ id: '12', name: '13. die Förderung internationaler Gesinnung, der Toleranz auf allen Gebieten der Kultur und des Völkerverständigungsgedankens' },
	{ id: '13', name: '14. die Förderung des Tierschutzes' },
	{ id: '14', name: '15. die Förderung der Entwicklungszusammenarbeit' },
	{ id: '15', name: '16. die Förderung von Verbraucherberatung und Verbraucherschutz' },
	{ id: '16', name: '17. die Förderung der Fürsorge für Strafgefangene und ehemalige Strafgefangene' },
	{ id: '17', name: '18. die Förderung der Gleichberechtigung von Frauen und Männern' },
	{ id: '18', name: '19. die Förderung des Schutzes von Ehe und Familie' },
	{ id: '19', name: '20. die Förderung der Kriminalprävention' },
	{ id: '20', name: '21. die Förderung des Sports (Schach gilt als Sport)' },
	{ id: '21', name: '22. die Förderung der Heimatpflege und Heimatkunde' },
	{ id: '22', name: '23. die Förderung der Tierzucht, der Pflanzenzucht, der Kleingärtnerei, des traditionellen Brauchtums einschließlich des Karnevals, der Fastnacht und des Faschings, der Soldaten- und Reservistenbetreuung, des Amateurfunkens, des Modellflugs und des Hundesports' },
	{ id: '23', name: '24. die allgemeine Förderung des demokratischen Staatswesens im Geltungsbereich dieses Gesetzes; hierzu gehören nicht Bestrebungen, die nur bestimmte Einzelinteressen staatsbürgerlicher Art verfolgen oder die auf den kommunalpolitischen Bereich beschränkt sind' },
	{ id: '24', name: '25. die Förderung des bürgerschaftlichen Engagements zugunsten gemeinnütziger, mildtätiger und kirchlicher Zwecke' },
	{ id: '25', name: '26. die Förderung der Unterhaltung und Pflege von Friedhöfen und die Förderung der Unterhaltung von Gedenkstätten für nichtbestattungspflichtige Kinder und Föten' }
];

export const APPLICATION_STATUSES = {
	'0': 'Entwurf',
	'1': 'Eingereicht',
	'2': 'Bestätigt',
	'3': 'Abgelehnt'
};

export const SDGS = [
	{ id: '0', name: '1. Keine Armut' },
	{ id: '1', name: '2. Kein Hunger' },
	{ id: '2', name: '3. Gesundheit und Wohlergehen' },
	{ id: '3', name: '4. Hochwertige Bildung' },
	{ id: '4', name: '5. Geschlechter-Gleichheit' },
	{ id: '5', name: '6. Sauberes Wasser und Sanitär-Einrichtungen' },
	{ id: '6', name: '7. Bezahlbare und saubere Energie' },
	{ id: '7', name: '8. Menschenwürdige Arbeit und Wirtschaftswachstum' },
	{ id: '8', name: '9. Industrie, Innovation und Infrastruktur' },
	{ id: '9', name: '10. Weniger Ungleichheiten' },
	{ id: '10', name: '11. Nachhaltige Städte und Gemeinden' },
	{ id: '11', name: '12. Nachhaltiger Konsum und Produktion' },
	{ id: '12', name: '13. Maßnahmen zum Klimaschutz' },
	{ id: '13', name: '14. Leben unter Wasser' },
	{ id: '14', name: '15. Leben an Land' },
	{ id: '15', name: '16. Frieden, Gerechtigkeit und starke Institutionen' },
	{ id: '16', name: '17. Partnerschaften zur Erreichung der Ziele' },
	{ id: '17', name: 'Keine / Nicht zutreffend' }
];

export const FIRST_STEP_FIELDS = [
	'contact_firstname',
	'contact_lastname',
	'contact_function',
	'organization_title',
	'organization_site',
	'organization_address',
	'organization_zip',
	'organization_city',
	'organization_email',
	'organization_phone'
];

export const SECOND_STEP_FIELDS = [
	'organization_account_owner',
	'organization_iban',
	'organization_can_provide_exemption',
	'organization_certificate_binary',
	'organization_purpose',
	'organization_purpose_detailed'
];

export const SUPPORTED_FORMATS = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];
export const ATTACHMENT_FIELDS = ['organization_certificate_binary', 'project_image_binary'];
export const LIMITS_REACHED = `Upload fehlgeschlagen. Bitte stelle sicher, dass eine Einzeldatei nicht größer als 5 MB
	ist und die Gesamtgröße aller Dateien 8 MB nicht übersteigt.`;
export const CACHED = 'Förderantrag erfolgreich zwischengespeichert';
export const SUBMITTED = 'Antrag erfolgreich eingereicht';

export const APP_HEADER = 'WirWunder hilft dir bei der Finanzierung deines Projektes';
export const APP_BODY = `
	Bitte fülle das Antragsformular <b>wahrheitsgemäß</b> aus und füge so
	viele <b>Nachweise</b> (z. B. eine Freistellung durch das Finanzamt) wie möglich bei, damit deine
	Sparkasse	sich ein gutes Bild von deinem Projekt und deinen finanziellen Bedürfnissen machen kann.
	Die Sparkasse wird den Antrag bearbeiten und sich bei Rückfragen an die von
	dir	<b>eingetragene Kontaktadresse</b> wenden.
	<br/><br/>
	Bei Fragen deinerseits (z. B. zu den Vergabekriterien), kannst du dich gerne an die
	zuständigen	<b>Ansprechpartner deiner Sparkasse</b> wenden.
	<br/><br/>
	Wichtig: Bitte denk daran, das Formular <b>abzuspeichern</b>, wenn du bei der Antragsstellung eine
	längere	Pause machst. In deinem <b>WirWunder-Profil</b> kannst du alle deine gespeicherten
	Anträge einsehen.
`;

export const PROJECT_HEADER = 'Direktförderung bei deiner Sparkasse beantragen';
export const PROJECT_BODY = `
	Ist dein Projekt nicht gemeinnützig oder benötigt nur eine geringe Fördersumme,
	dann kannst du hier eine <b>Direktförderung bei deiner Sparkasse</b> beantragen.
	<br/><br/>
	Achtung: Hier verzichtest du auf finanzielle Unterstützung durch die Crowd!
	Falls du über betterplace.org ein Sammelprojekt erstellst, ist das ähnlich wie ein
	Online-Antrag bei der Sparkasse und du hast zusätzlich die Chance auf Unterstützung durch die Crowd.
`;

export const CONTACT_FUNCTION = `
	Welche Rolle bekleidest du in deiner Organisation? Du solltest deine Organisation
	in der Öffentlichkeit vertreten dürfen.
`;

export const ORG_CAN_PROVIDE_EXEMPTION = `
	Ist deine Organisation nach § 5 Abs. 1 Nr. 9 des Körperschaftsteuergesetzes von der
	Körperschaftsteuer befreit? Wenn ja, lade bitte eine Kopie des gültigen Bescheides
	oder eines anderen Nachweises hoch.
`;

export const ORG_PURPOSE_DETAILED = `
	Bitte gib den Förderzweck an, für den die Mittel verwendet werden und für den deine Organisation satzungsgemäß vom Finanzamt freigestellt ist.
`;

export const PROJECT_TITLE = 'Bitte beschreibe dein Vorhaben in einem Satz.';

export const PROJECT_DESCRIPTION = `
		Bitte beschreibe in einigen prägnanten Sätzen: Um was geht es bei deinem Vorhaben? Weshalb wird es durchgeführt?
		Was ist das Ziel? Und wer ist daran beteiligt?
`;

export const PROJECT_IMAGE_BINARY = `
	Lade ein Foto oder Bild hoch, das dein Vorhaben gut repräsentiert. Da es bei einer erfolgreichen Förderung
	möglicherweise in der Öffentlichkeit gezeigt wird, musst du über die entsprechenden Rechte bzw. Zustimmungen der
	abgebildeten Personen verfügen.
`;

export const PROJECT_AMOUNT = `
	Normalerweise handelt es sich dabei um den Fehlbetrag aus Kosten minus Einnahmen.
`;

export const PLAINTEXT_PLAN = `
	Du kannst für Details auch gerne zusätzlich das Kommentarfeld nutzen oder einen separaten Finanzierungsplan unter
	"Weitere Anlagen hochladen" hochladen.
`;

export const PROJECT_FINANCING_TOTAL = 'Was ist die Summe aller anfallenden Kostenpositionen?';

export const PROJECT_FINANCING_OWN = `
	Wie viel kann aus Eigenmitteln sowie aus möglichen Einnahmen aus dem Vorhaben genommen werden?
`;

export const PROJECT_FINANCING_FURTHER = 'Wie viel wurde von Dritten bereits zugesagt?';

export const PROJECT_FINANCING_OTHER = 'Wie viel wurde bei Dritten beantragt, aber noch nicht zugesagt?';

export const COMMENT = `
	Gibt es noch Informationen, die wir wissen sollten? Beispielsweise Details zu deiner Organisation,
	zum Vorhaben oder zur Finanzierung.
`;

export const ATTACHMENT_BINARY = `
	Du kannst auch Dokumente zu deiner Organisation, das Vorhaben oder die Finanzierung
	(z. B. ein separater Finanzierungsplan) hochladen.
`;
