import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[KPI]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	KPI_LOAD: `${prefix} kpi_load`,
	KPI_SET: `${prefix} kpi_set`
};

const actions = {
	...TYPES,
	setKpi: makeActionCreator(TYPES.KPI_SET, 'kpi'),
	loadKpi: makeActionCreator(TYPES.KPI_LOAD)
};

export default actions;
export { actions as kpiAction };
