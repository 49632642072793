import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Branches]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	BRANCHES_LOAD: `${prefix} branches_load`,
	BRANCHES_SET: `${prefix} branches_set`
};

const actions = {
	...TYPES,
	setBranches: makeActionCreator(TYPES.BRANCHES_SET, 'branches'),
	loadBranches: makeActionCreator(TYPES.BRANCHES_LOAD)
};

export default actions;
export { actions as branchesAction };
