/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { NotificationUtils } from '../../utils/NotificationUtils';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		NotificationUtils.error(error || errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <h2 className='mt-3 ml-3 text-muted'>Ein Fehler ist aufgetreten. Bitte versuche es erneut.</h2>;
		}

		return this.props.children;
	}
}

export { ErrorBoundary };
export default ErrorBoundary;
