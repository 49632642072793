import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[REDIRECT]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	ADD_REDIRECT: `${prefix} add`,
	REMOVE_REDIRECT: `${prefix} remove`
};

const actions = {
	...TYPES,
	setRedirect: makeActionCreator(TYPES.ADD_REDIRECT, 'redirect'),
	removeRedirect: makeActionCreator(TYPES.REMOVE_REDIRECT)
};

export default actions;
export { actions as redirectActions };
