import { all, takeLatest, put, call } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { RequestReleaseService } from '../../services/RequestReleaseService';

import actions from './actions';

function* loadRequestRelease({ payload }) {
	yield put(actions.setRequestReleaseLoading({ status: true }));

  const response = yield call(RequestReleaseService.getRequestRelease, payload.hash);

	if (response?.status == 200) {
		yield put(actions.setRequestRelease(response.data));
	} else {
		NotificationManager.error(`${response}`, 'Freigabe beantragen', 7000);
	}

	yield put(actions.setRequestReleaseLoading({ status: false }));
}

function* createRequestRelease({ payload }) {
	yield put(actions.setRequestReleaseCreating({ status: true }));

  const response = yield call(RequestReleaseService.createRequestRelease, payload);

	if (response?.status == 200) {
		NotificationManager.success('Erfolgreich gespeichert');
	} else {
		yield put(actions.setRequestReleaseError(response));
		NotificationManager.error('Etwas ist schief gelaufen');
	}

	yield put(actions.setRequestReleaseCreating({ status: false }));
}

export default function* requestReleaseSaga() {
	yield all([takeLatest(actions.REQUEST_RELEASE_LOAD, loadRequestRelease)]);
	yield all([takeLatest(actions.REQUEST_RELEASE_CREATE, createRequestRelease)]);
}
