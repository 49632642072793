import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadBranchNews({ payload }) {
	const branchNews = yield call(AuthService.branchNewsBackOffice, payload.id);
	// const defaultStaticPages = yield call(AuthService.defaultStaticPagesBackOffice);

	yield put(actions.setBranchNews(branchNews || []));
}

export default function* partnersSaga() {
	yield all([takeLatest(actions.BRANCH_NEWS_LOAD, loadBranchNews)]);
}
