import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	faqResponse: null
});

export default function faqReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.FAQ_SET: {
			return Immutable.set(state, 'faqResponse', payload.faq);
		}
		default: {
			return state;
		}
	}
}
