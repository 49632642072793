import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Projects Supported]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	SUPPORTED_LOAD: `${prefix} supported_load`,
	SUPPORTED_SET: `${prefix} supported_set`,
	SUPPORTED_PS_LOAD: `${prefix} supported_ps_load`,
	SUPPORTED_PS_SET: `${prefix} supported_ps_set`,
	SUPPORTED_IMPORTED_LOAD: `${prefix} supported_imported_load`,
	SUPPORTED_IMPORTED_SET: `${prefix} supported_imported_set`,
	SUPPORTED_SP_LOAD: `${prefix} supported_sp_load`,
	SUPPORTED_SP_SET: `${prefix} supported_sp_set`,
	SUPPORTED_FDN_LOAD: `${prefix} supported_FDN_load`,
	SUPPORTED_FDN_SET: `${prefix} supported_FDN_set`
};

const actions = {
	...TYPES,
	setSupported: makeActionCreator(TYPES.SUPPORTED_SET, 'contacts'),
	loadSupported: makeActionCreator(TYPES.SUPPORTED_LOAD, 'id'),
	setSupportedPS: makeActionCreator(TYPES.SUPPORTED_PS_SET, 'contacts'),
	loadSupportedPS: makeActionCreator(TYPES.SUPPORTED_PS_LOAD, 'id'),
	setImportedSupported: makeActionCreator(TYPES.SUPPORTED_IMPORTED_SET, 'contacts'),
	loadImportedSupported: makeActionCreator(TYPES.SUPPORTED_IMPORTED_LOAD, 'id'),
	setSupportedSP: makeActionCreator(TYPES.SUPPORTED_SP_SET, 'contacts'),
	loadSupportedSP: makeActionCreator(TYPES.SUPPORTED_SP_LOAD, 'id'),
	setSupportedFDN: makeActionCreator(TYPES.SUPPORTED_FDN_SET, 'contacts'),
	loadSupportedFDN: makeActionCreator(TYPES.SUPPORTED_FDN_LOAD, 'id')
};

export default actions;
export { actions as supportedAction };
