import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Faq]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	FAQ_LOAD: `${prefix} faq_load`,
	FAQ_SET: `${prefix} faq_set`
};

const actions = {
	...TYPES,
	loadFaq: makeActionCreator(TYPES.FAQ_LOAD),
	setFaq: makeActionCreator(TYPES.FAQ_SET, 'faq')
};

export default actions;
export { actions as FaqAction };
