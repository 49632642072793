import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Page4]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	PAGE4_SET: `${prefix} page4`
};

const actions = {
	...TYPES,
	setPage4: makeActionCreator(TYPES.PAGE4_SET, 'page4')
};

export default actions;
export { actions as page4Action };
