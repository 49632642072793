import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    const isFromSearch = prevProps.location.pathname.toLowerCase().includes('projekte-entdecken');
    const isOnSearch = this.props.location.pathname.toLowerCase().includes('projekte-entdecken');
    const isOnAction = this.props.location.pathname.toLowerCase().includes('aktion');
    const isOnContact = this.props.location.hash.toLowerCase().includes('#kontakt');
    const activeSearch = prevProps.location.pathname.toLowerCase() === this.props.location.pathname.toLowerCase() &&
      prevProps.location.search !== this.props.search;

    if (isFromSearch || isOnSearch || isOnAction || activeSearch || isOnContact) {
      return;
    }

    setTimeout(() => window.scrollTo(0, 0), 0);
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
