import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[StiftungSettings]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	SETTINGS_ST_LOAD: `${prefix} settings_st_load`,
	SETTINGS_ST_SET: `${prefix} settings_st_set`
};

const actions = {
	...TYPES,
	setSettingsSt: makeActionCreator(TYPES.SETTINGS_ST_SET, 'settings_st'),
	loadSettingsSt: makeActionCreator(TYPES.SETTINGS_ST_LOAD, 'branchId')
};

export default actions;
export { actions as settingsStActions };
