export const API_ROUTES = {
  root: '/',

  // Auth
  authLogin: '/auth',
  authProfile: '/users/login',
  login: '/user/login',
  register: '/users',
  partners: '/partners/',
  kpi: '/kpi/',
  projects: '/projects/',
  branches: '/branches/',
  reset: '/user/resetPassword',
  reset2: '/user/setPassword'
};

export const UI_ROUTES = {
  root: '/',

  // Auth
  login: '/',
  register: '/auth',
  complete: '/registrierung-vervollstaendigen',
  resetpassword: '/new-password',
  resetpasswordcomplete: '/new-password/complete',
  authinspark: '/auth-sparkasse',
  projects: '/projects/',
  start: '/starten',
  regionalportal: '/',
  regionalportalId: '/regionalportalId/',
  projectsSupported: '/supported/',
  kontakt: '/kontakt',
  userProfile: '/profile',
  page404: '/404',
  wasIstWirWunder: '/was-ist-wirwunder',
  footer11: '/so-gehts-unterstuetzer',
  footer12: '/so-gehts-organisationen',
  footer13: '/faq',
  footer31: '/agb',
  footer32: '/datenschutzbestimmungen',
  footer33: '/impressum',
  footer34: '/cookie',
  action: '/aktion',
  // fluthilfe: '/iframetest',
  fluthilfe: '/fluthilfe',
  customStaticPage: '/seite',
  requestRelease: '/foerdermittel-freigabe',
  totalFinanced: '/insgesamt-finanziert',
  onlineForm: '/online-form'
};
