import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Partners]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	PARTNERS_LOAD: `${prefix} partners_load`,
	PARTNERS_SET: `${prefix} partners_set`,
	PARTNERS_BACKOFFICE_LOAD: `${prefix} partners_backoffice_load`,
	PARTNERS_BACKOFFICE_SET: `${prefix} partners_backoffice_set`
};

const actions = {
	...TYPES,
	setPartners: makeActionCreator(TYPES.PARTNERS_SET, 'partners'),
	loadPartners: makeActionCreator(TYPES.PARTNERS_LOAD),
	loadPartnersBackoffice: makeActionCreator(TYPES.PARTNERS_BACKOFFICE_LOAD, 'id'),
	setPartnersBackoffice: makeActionCreator(TYPES.PARTNERS_BACKOFFICE_SET, 'partnersBackoffice')
};

export default actions;
export { actions as partnersAction };
