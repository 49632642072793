import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[RequestRelease]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	REQUEST_RELEASE_LOAD: `${prefix} request_release_load`,
	REQUEST_RELEASE_SET: `${prefix} request_release_set`,
	REQUEST_RELEASE_CREATE: `${prefix} request_release_create`,
	REQUEST_RELEASE_ERROR_SET: `${prefix} request_release_error_set`,
	REQUEST_RELEASE_LOADING_SET: `${prefix} request_release_loading_set`,
	REQUEST_RELEASE_CREATING_SET: `${prefix} request_release_creating_set`
};

const actions = {
	...TYPES,
	setRequestRelease: makeActionCreator(TYPES.REQUEST_RELEASE_SET, 'requestRelease'),
	loadRequestRelease: makeActionCreator(TYPES.REQUEST_RELEASE_LOAD, 'hash'),
	createRequestRelease: makeActionCreator(TYPES.REQUEST_RELEASE_CREATE, 'hash', 'formData'),
	setRequestReleaseError: makeActionCreator(TYPES.REQUEST_RELEASE_ERROR_SET, 'requestReleaseError'),
	setRequestReleaseLoading: makeActionCreator(TYPES.REQUEST_RELEASE_LOADING_SET, 'isRequestReleaseLoading'),
	setRequestReleaseCreating: makeActionCreator(TYPES.REQUEST_RELEASE_CREATING_SET, 'isRequestReleaseCreating')
};

export default actions;
export { actions as requestReleaseAction };
