import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { NotificationManager } from 'react-notifications';
import { store } from '../store';

import { UI_ROUTES } from '../../constants/routes';
import { AuthService } from '../../services/AuthService';
import { StorageUtils } from '../../utils/StorageUtils';

import actions from './actions';
import buttonsAction from '../buttons/actions';

// import { mockToken, mockUser } from '../../utils/mocks/auth';

function* login({ payload }) {
  const { credentials } = payload;
  const token = yield call(AuthService.login, credentials);

  if (!token) {
    return;
  }

  yield call(StorageUtils.storeToken, token);

  // yield delay(10000);

  const user = yield call(AuthService.profile);
  if (user && user.email) {
    yield put(actions.profileRefresh(user));

    if (!user.branch_id) {
      yield put(push(UI_ROUTES.start));
    } else {
      const data = store.getState();
      const branches = data['Branches'].branches.data.data;
      const correctBranch = branches.find(i => i.id === user.branch_id);
      if (correctBranch && correctBranch.alias) {
        yield put(push(`/${correctBranch.alias}`));
      } else {
        yield put(push(UI_ROUTES.root));
      }
    }
  }
  // else {
  //   yield put(actions.profileRefresh(null));
  //   yield call(StorageUtils.clearAll);
  //   yield put(push(UI_ROUTES.register));
  // }
}

function* update({ payload }) {
  const { credentials, data, options } = payload;
  const test = yield call(AuthService.update, data, credentials.id);
  if (test) {
    // yield call(StorageUtils.storeToken, credentials);
    yield put(actions.profileRefresh(credentials));
    NotificationManager.success('Deine Änderungen wurden gespeichert.');
    if (!options.isChangeLocation) {
      if (options.active_ref) {
        yield put(push(options.active_ref));
      } else {
        yield put(push(UI_ROUTES.start));
      }
    }
  }
}

function* register({ payload }) {
  const { credentials } = payload;
  const test = yield call(AuthService.register, credentials);
  if (test) {
    yield put(push(UI_ROUTES.complete));
  }
}

function* resetpassword({ payload }) {
  const { credentials } = payload;
  yield put(buttonsAction.setLoading(true));
  const test = yield call(AuthService.reset, credentials);
  yield put(buttonsAction.setLoading(false));
  if (test) {
    yield put(push(UI_ROUTES.resetpasswordcomplete));
  }
}

function* resetpassword2({ payload }) {
  const { credentials } = payload;
  yield put(buttonsAction.setLoading(true));
  const test = yield call(AuthService.reset2, credentials);
  yield put(buttonsAction.setLoading(false));
  if (test) {
    yield put(push(UI_ROUTES.register));
  }
}

function* confirmEmail({ payload }) {
  yield put(actions.setChangeEmailLoading({ status: true }));

  const { credentials } = payload;
  const response = yield call(AuthService.confirmEmail, credentials);

  if (response?.status == 200) {
    NotificationManager.success(
      'Bitte melde dich nun mit deiner neuen E-Mail-Adresse an.',
      'Deine neue E-Mail-Adresse wurde bestätigt.',
      20000
    );
  } else {
    NotificationManager.error('Ihre neue E-Mail-Adresse wurde nicht bestätigt.');
  }

  yield put(push(UI_ROUTES.register));
  yield put(actions.setChangeEmailLoading({ status: false }));
}

function* logout() {
  yield put(actions.profileRefresh(null));
  yield call(AuthService.logout);
  yield call(StorageUtils.clearAll);
  yield put(push(UI_ROUTES.register));
}

export default function* authSaga() {
  yield all([
    takeLatest(actions.LOGIN, login),
    takeLatest(actions.LOGOUT, logout),
    takeLatest(actions.REGISTER, register),
    takeLatest(actions.RESETPASSWORD, resetpassword),
    takeLatest(actions.RESETPASSWORD2, resetpassword2),
    takeLatest(actions.CONFIRM_EMAIL, confirmEmail),
    takeLatest(actions.UPDATE, update)
  ]);
}
