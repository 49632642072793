import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadFaq() {
	const faq = yield call(AuthService.faqBackOffice);
	if (faq) yield put(actions.setFaq(faq));
}

export default function* faqSaga() {
	yield all([takeLatest(actions.FAQ_LOAD, loadFaq)]);
}
