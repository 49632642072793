import axios from 'axios';
import { NotificationUtils } from '../utils/NotificationUtils';

import { buildServers, getServerByHost } from '../config';
import { API_ROUTES } from '../constants/routes';

const servers = buildServers();

const hostname = window.location.hostname;
const settings = getServerByHost(hostname);

const agent = axios.create({
  baseURL: settings.API_URL.url,
});

const agentBackOffice = axios.create({
  baseURL: settings.BACKOFFICE_API_URL.url,
});

// import { StorageUtils } from '../utils/StorageUtils';
// agent.interceptors.request.use((config) => {
//   // const token = StorageUtils.restoreToken();
//   const token = settings.API_URL.token;
//   config = {
//     ...config,
//     headers: {
//       ...config.headers,
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   return config;
// });

agentBackOffice.interceptors.request.use((config) => {
  const token = settings.BACKOFFICE_API_URL.token;
  config = {
    ...config,
    headers: {
      ...config.headers,
      // Authorization: `Bearer ${token}`,
    },
  };
  return config;
});

agent.interceptors.response.use(
  (res) => res || { success: true, data: {} },
  (err) => {
    const { config, status, response } = err;
    const statusCode = status || (response && response.status);
    const isLogin = config?.url && config.url.includes(API_ROUTES.login);

    if (statusCode && statusCode === 401) {
      if (!isLogin) {
        NotificationUtils.throwUnauthorized();
        return;
      }
    }

    let { message } = err;
    if (response && response.data && response.data.error) {
      message = response.data.error;
    }
    err.errorText = message;

    throw err;
  }
);

agentBackOffice.interceptors.response.use(
  (res) => res || { success: true, data: {} },
  (err) => {
    const { config, status, response } = err;
    const statusCode = status || (response && response.status);
    const isLogin = config?.url && config.url.includes(API_ROUTES.login);

    if (statusCode && statusCode === 401) {
      if (!isLogin) {
        NotificationUtils.throwUnauthorized();
        return;
      }
    }

    let { message } = err;
    if (response && response.data && response.data.error) {
      message = response.data.error;
    }
    err.errorText = message;

    throw err;
  }
);

export { agent, agentBackOffice, servers, settings };
