import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadStaticPages() {
	const staticPages = yield call(AuthService.staticPagesBackOffice);
	if (staticPages) yield put(actions.setStaticPages(staticPages));
}

export default function* partnersSaga() {
	yield all([takeLatest(actions.STATIC_PAGES_LOAD, loadStaticPages)]);
}
