import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadKpi() {
	const kpi = yield call(AuthService.kpi);
	yield put(actions.setKpi(kpi));
}

export default function* kpiSaga() {
	yield all([takeLatest(actions.KPI_LOAD, loadKpi)]);
}
