import { createSelector } from 'reselect';

const user = ({ Auth }) => Auth.user;
const isChangeEmailLoading = ({ Auth }) => Auth.isChangeEmailLoading.status;

export const selectUser = createSelector([user], user => user);

export const selectIsLoggedIn = createSelector([selectUser], user => Boolean(user && user.email));
export const selectIsChangeEmailLoading = createSelector(
  [isChangeEmailLoading], isChangeEmailLoading => isChangeEmailLoading
);
