import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
  branchHeaderImageResponse: null
});

export default function branchHeaderImageReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.BRANCH_HEADER_IMAGE_SET: {
      return Immutable.set(
        state,
        'branchHeaderImageResponse',
        payload.branchHeaderImage
      );
    }
    default: {
      return state;
    }
  }
}
