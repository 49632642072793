import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	projects: null
});

export default function projectsReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PROJECTS_SET: {
			return Immutable.set(state, 'projects', payload.projects);
		}
		default: {
			return state;
		}
	}
}
