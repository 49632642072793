import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Contacts]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	CONTACTS_LOAD: `${prefix} contacts_load`,
	CONTACTS_SET: `${prefix} contacts_set`
};

const actions = {
	...TYPES,
	setContacts: makeActionCreator(TYPES.CONTACTS_SET, 'contacts'),
	loadContacts: makeActionCreator(TYPES.CONTACTS_LOAD, 'id')
};

export default actions;
export { actions as contactsAction };
