import { all, takeLatest, put, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import { UI_ROUTES } from '../../constants/routes';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadPartners() {
	const partners = yield call(AuthService.partners);
	yield put(actions.setPartners(partners));
}

function* loadPartnersBackoffice({ payload }) {
	const partnersBackOffice = yield call(AuthService.partnersBackOffice, payload.id);
	yield put(actions.setPartnersBackoffice(partnersBackOffice || []));
}

export default function* partnersSaga() {
	yield all([takeLatest(actions.PARTNERS_LOAD, loadPartners)]);
	yield all([takeLatest(actions.PARTNERS_BACKOFFICE_LOAD, loadPartnersBackoffice)]);
}
