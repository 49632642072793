import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	redirectTo: null
});

export default function partnersReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.ADD_REDIRECT: {
			return Immutable.set(state, 'redirectTo', payload.redirect);
		}
    case actions.REMOVE_REDIRECT: {
      return Immutable.set(state, 'redirectTo', null);
    }
		default: {
			return state;
		}
	}
}
