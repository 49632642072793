import styled from 'styled-components';

import { THEME } from '../../constants/theme';

const { height } = THEME;

export const Header = styled.header`
	display: flex;
	align-items: center;
	min-height: ${height.header}px;
	background-color: #ff0000;
`;
