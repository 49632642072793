import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadSupported({ payload }) {
	const supported = yield call(AuthService.projectsSupportedBackOffice, payload.id);
	if (supported) {
		yield put(actions.setSupported(supported));
	}
}

function* loadSupportedImported({ payload }) {
	const supportedImported = yield call(AuthService.projectsSupportedImportedBackOffice, payload.id);
	if (supportedImported) {
		yield put(actions.setImportedSupported(supportedImported));
	}
}

function* loadSupportedPS({ payload }) {
	const supported_ps = yield call(AuthService.allProjectsSupportedBackOffice, payload.id);

	if (supported_ps) {
		yield put(actions.setSupportedPS(supported_ps));
	}
}

function* loadSupportedSP({ payload }) {
	const supported_sp = yield call(AuthService.projectsSupportedSponsoring, payload.id);

	if (supported_sp) {
		yield put(actions.setSupportedSP(supported_sp));
	}
}

function* loadSupportedFDN({ payload }) {
	const supported_fdn = yield call(AuthService.projectsSupportedFoundation, payload.id);

	if (supported_fdn) {
		yield put(actions.setSupportedFDN(supported_fdn));
	}
}

export default function* supportedSaga() {
	yield all([takeLatest(actions.SUPPORTED_LOAD, loadSupported)]);
	yield all([takeLatest(actions.SUPPORTED_IMPORTED_LOAD, loadSupportedImported)]);
	yield all([takeLatest(actions.SUPPORTED_PS_LOAD, loadSupportedPS)]);
	yield all([takeLatest(actions.SUPPORTED_SP_LOAD, loadSupportedSP)]);
	yield all([takeLatest(actions.SUPPORTED_FDN_LOAD, loadSupportedFDN)]);
}
