import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	kpi: null
});

export default function partnersReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.KPI_SET: {
			return Immutable.set(state, 'kpi', payload.kpi);
		}
		default: {
			return state;
		}
	}
}
