import { all, takeLatest, put, call } from 'redux-saga/effects';
import { OnlineFormService } from '../../services/OnlineFormService';
import { NotificationManager } from 'react-notifications';
import { LIMITS_REACHED, CACHED, SUBMITTED } from '../../constants/onlineForm';

import actions from './actions';

function* loadApplications({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const	response = yield call(OnlineFormService.getApplications, payload);

	if (response?.status == 200) {
		yield put(actions.setApplications(response.data));
	} else {
		NotificationManager.error(`${response}`, 'Applications', 7000);
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

function* loadApplication({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const response = yield call(OnlineFormService.getApplicationsById, payload.id);

	if (response?.status == 200) {
		yield put(actions.setApplication(response.data));
	} else {
		NotificationManager.error(`${response}`, 'Application', 7000);
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

function* updateApplication({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const response = yield call(OnlineFormService.updateApplication, payload);

	if (response?.status == 200) {
		yield put(actions.setApplication(response.data));
		NotificationManager.success(response.data.status ? SUBMITTED : CACHED);
	} else {
		yield put(actions.setApplicationError(response));
		NotificationManager.error(response?.status == 413 ? LIMITS_REACHED : 'Etwas ist schief gelaufen');
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

function* createApplication({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const response = yield call(OnlineFormService.createApplication, payload);

	if (response?.status == 201) {
		yield put(actions.setApplication(response.data));
		const applicationUrl = `/${payload.name}/online-form/${response.data.application_id}/step/${payload.step}`
		window.location.href = response.data.status > 0 ? '/profile#applications' : applicationUrl;
		NotificationManager.success(response.data.status ? SUBMITTED : CACHED);
	} else {
		yield put(actions.setApplicationError(response));
		NotificationManager.error(response?.status == 413 ? LIMITS_REACHED : 'Etwas ist schief gelaufen');
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

function* deleteApplication({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const response = yield call(OnlineFormService.deleteApplication, payload.id);

	if (response?.status == 200) {
		yield put(actions.setApplications(payload.applications.filter(a => a.application_id !== payload.id)));
	} else {
		NotificationManager.error(`${response}`, 'Application', 7000);
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

function* deleteAttachment({ payload }) {
	yield put(actions.setApplicationLoading({ status: true }));
	const response = yield call(OnlineFormService.removeAttachment, payload);

	if (response?.status == 200) {
		const { attachment, attachment_url } = response.data;

		yield put(actions.setApplication({ ...payload.application, attachment, attachment_url }));
		NotificationManager.success('Datei erfolgreich gelöscht');
	} else {
		yield put(actions.setApplicationError(response));
		NotificationManager.error('Etwas ist schief gelaufen');
	}

	yield put(actions.setApplicationLoading({ status: false }));
}

export default function* applicationsSaga() {
	yield all([takeLatest(actions.APPLICATIONS_LOAD, loadApplications)]);
	yield all([takeLatest(actions.APPLICATION_LOAD, loadApplication)]);
	yield all([takeLatest(actions.APPLICATION_UPDATE, updateApplication)]);
	yield all([takeLatest(actions.APPLICATION_CREATE, createApplication)]);
	yield all([takeLatest(actions.ATTACHMENT_DELETE, deleteAttachment)]);
	yield all([takeLatest(actions.APPLICATION_DELETE, deleteApplication)]);
}
