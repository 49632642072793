import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadBranchHeaderImage({ payload }) {
  const branchhHeaderImage = yield call(
    AuthService.getBranchHeaderImage,
    payload.id
  );
  yield put(actions.setBranchHeaderImage(branchhHeaderImage));
}

export default function* partnersSaga() {
  yield all([
    takeLatest(actions.BRANCH_HEADER_IMAGE_LOAD, loadBranchHeaderImage)
  ]);
}
