import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	partners: null,
	partnersBackoffice: null
});

export default function partnersReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PARTNERS_SET: {
			return Immutable.set(state, 'partners', payload.partners);
		}
		case actions.PARTNERS_BACKOFFICE_SET: {
			return Immutable.set(state, 'partnersBackoffice', payload.partnersBackoffice);
		}
		default: {
			return state;
		}
	}
}
