import React from 'react';

import { RestrictedRoutes } from '../../routes';

const Root = () => {
	return (
		<>
			<RestrictedRoutes />
		</>
	);
};

export default Root;
export { Root };
