import { API_ROUTES } from '../constants/routes';
import { NotificationUtils } from '../utils/NotificationUtils';
import { BaseService } from './BaseService';

class RequestReleaseService extends BaseService {
  constructor() {
    super(API_ROUTES.root);
  }

  getRequestRelease = async (hash) => {
    const { agentBackOffice } = this;

    try {
      const url = `v1.0/api/budget-transaction-request/${hash}`;

      return await agentBackOffice.get(url);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.errorText || err.message;
    }
  };

  createRequestRelease = async (payload) => {
    const { hash, formData } = payload;
    const { agentBackOffice } = this;
    try {
      const url = `v1.0/api/budget-transaction-response/${hash}`;

      return await agentBackOffice.post(url, formData);
    } catch (err) {
      NotificationUtils.error(err.errorText || err.message);
      return err.response;
    }
  };
}

const Service = new RequestReleaseService();

export default Service;
export { Service as RequestReleaseService };
