import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';
import authAction, { authActions } from '../auth/actions';

function* loadBranches() {
	const	branches = yield call(AuthService.branches);
	const branchesFiltered = branches.data.data.filter(branch => branch.active);
	const branchesForStarten = branches.data.data.filter(branch => !branch.active);
	const branchesReplaced = { ...branches, data: { data: branchesFiltered, starten: branchesForStarten } };

	const state = yield select();
	const userBranchId = state?.Auth?.user?.branch_id;
	if (userBranchId) {
		const filtered = branchesFiltered.filter(branch => branch.id === userBranchId);
		// inactive user branch
		if (!filtered.length) {
			yield put(authActions.update({ ...state.Auth.user, branch_id: null }, { branch_id: null }));
			yield put(authAction.logout());
		}
	}
	yield put(actions.setBranches(branchesReplaced));
	// yield put(actions.setBranches(branches));
}

export default function* branchesSaga() {
	yield all([takeLatest(actions.BRANCHES_LOAD, loadBranches)]);
}
