import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[PsSettings]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	SETTINGS_ZW_LOAD: `${prefix} settings_zw_load`,
	SETTINGS_ZW_SET: `${prefix} settings_zw_set`
};

const actions = {
	...TYPES,
	setSettingsZw: makeActionCreator(TYPES.SETTINGS_ZW_SET, 'settings_zw'),
	loadSettingsZw: makeActionCreator(TYPES.SETTINGS_ZW_LOAD, 'branchId')
};

export default actions;
export { actions as settingsZwActions };
