import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadContacts({ payload }) {
	const contacts = yield call(AuthService.contacts, payload.id);
	yield put(actions.setContacts(contacts || []));
}

export default function* contactsSaga() {
	yield all([takeLatest(actions.CONTACTS_LOAD, loadContacts)]);
}
