import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	supported: null,
	supported_ps: null,
	supportedImported: null,
	supported_sp: null,
	supported_fdn: null
});

export default function supportedReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.SUPPORTED_SET: {
			return Immutable.set(state, 'supported', payload.contacts);
		}

		case actions.SUPPORTED_PS_SET: {
			return Immutable.set(state, 'supported_ps', payload.contacts);
		}

		case actions.SUPPORTED_SP_SET: {
			return Immutable.set(state, 'supported_sp', payload.contacts);
		}

		case actions.SUPPORTED_FDN_SET: {
			return Immutable.set(state, 'supported_fdn', payload.contacts);
		}

		case actions.SUPPORTED_IMPORTED_SET: {
			return Immutable.set(state, 'supportedImported', payload.contacts);
		}

		default: {
			return state;
		}
	}
}
