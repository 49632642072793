import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadSettingsSt({ payload }) {
	if (!payload.branchId) return;

	const result = yield call(AuthService.getStiftungSettings, payload.branchId);

	const settings_st = result && result.branch_id ? result : {};

	yield put(actions.setSettingsSt(settings_st));
}

export default function* settingsZwSaga() {
	yield all([takeLatest(actions.SETTINGS_ST_LOAD, loadSettingsSt)]);
}
