/**
 * Adhere alphabetical order for import sections and 'rootSaga' function.
 */

import { all, fork } from 'redux-saga/effects';

import appSaga from './app/saga';
import authSaga from './auth/saga';
import partnersSaga from './partners/saga';
import kpiSaga from './kpi/saga';
import projectsSaga from './projects/saga';
import branchesSaga from './branches/saga';
import branchNewsSaga from './branchnews/saga';
import staticPagesSaga from './staticpages/saga';
import faqSaga from './faq/saga';
import contactsSaga from './contacts/saga';
import supportedSaga from './projects-supported/saga';
import settingsZwSaga from './settingsZweckertrage/saga';
import settingsStSaga from './settingsStiftung/saga';
import branchHeaderImage from './branch-header-image/saga';
import applicationsSaga from './onlineForms/saga';
import requestReleaseSaga from './requestRelease/saga';

export default function* rootSaga() {
  yield all([
    fork(appSaga),
    fork(authSaga),
    fork(partnersSaga),
    fork(kpiSaga),
    fork(projectsSaga),
    fork(branchesSaga),
    fork(branchNewsSaga),
    fork(staticPagesSaga),
    fork(faqSaga),
    fork(contactsSaga),
    fork(supportedSaga),
    fork(settingsZwSaga),
    fork(settingsStSaga),
    fork(branchHeaderImage),
    fork(applicationsSaga),
    fork(requestReleaseSaga),
  ]);
}
