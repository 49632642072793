import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AuthService } from '../../services/AuthService';

import actions from './actions';

function* loadSettingsZw({ payload }) {
	if (!payload.branchId) return;

	const result = yield call(AuthService.getZweckertrageSettings, payload.branchId);

	const settings_zw = result && result.branch_id ? result : {};

	yield put(actions.setSettingsZw(settings_zw));
}

export default function* settingsZwSaga() {
	yield all([takeLatest(actions.SETTINGS_ZW_LOAD, loadSettingsZw)]);
}
