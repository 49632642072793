(function() {
	if (typeof window.Event === 'function') return false; //If not IE

	function CustomEvent(event, params) {
		params = params || {
			bubbles: false,
			cancelable: false,
			detail: undefined,
		};
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(
			event,
			params.bubbles,
			params.cancelable,
			params.detail
		);
		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;

	window.Event = CustomEvent;
})();
