import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Branch News]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	BRANCH_NEWS_LOAD: `${prefix} branch_news_load`,
	BRANCH_NEWS_SET: `${prefix} branch_news_set`
};

const actions = {
	...TYPES,
	loadBranchNews: makeActionCreator(TYPES.BRANCH_NEWS_LOAD, 'id'),
	setBranchNews: makeActionCreator(TYPES.BRANCH_NEWS_SET, 'branchNews')
};

export default actions;
export { actions as branchNewssAction };
