import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Buttons]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	BUTTONS: `${prefix} buttons`
};

const actions = {
	...TYPES,
	setLoading: makeActionCreator(TYPES.BUTTONS, 'LOADING')
};

export default actions;
export { actions as buttonsAction };
