import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Branch Header Image]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
  BRANCH_HEADER_IMAGE_LOAD: `${prefix} branch_header_image_load`,
  BRANCH_HEADER_IMAGE_SET: `${prefix} branch_header_image_set`
};

const actions = {
  ...TYPES,
  loadBranchHeaderImage: makeActionCreator(
    TYPES.BRANCH_HEADER_IMAGE_LOAD,
    'id'
  ),
  setBranchHeaderImage: makeActionCreator(
    TYPES.BRANCH_HEADER_IMAGE_SET,
    'branchHeaderImage'
  )
};

export default actions;
export { actions as branchHeaderImageAction };
