import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	contacts: null
});

export default function contactsReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.CONTACTS_SET: {
			return Immutable.set(state, 'contacts', payload.contacts);
		}

		default: {
			return state;
		}
	}
}
