import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	settings_zw: {}
});

export default function settingsZwReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.SETTINGS_ZW_SET: {
			return Immutable.set(state, 'settings_zw', payload.settings_zw);
		}
		default: {
			return state;
		}
	}
}
