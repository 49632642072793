import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	htaccess: false
});

export default function basicReducer(state = initState, { type }) {
	switch (type) {
		case actions.HTACCESS: {
			return Immutable.set(state, 'htaccess', true);
		}
		default: {
			return state;
		}
	}
}
