import React from 'react';
import * as PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

import { AbsoluteLayout } from '../layouts';

const AppLoader = ({ visible }) => {
	if (!visible) {
		return null;
	}

	return (
		<AbsoluteLayout>
			<div className='d-flex justify-content-center align-items-center min-vh-100'>
				<span className='ml-3 text-uppercase' style={{ fontSize: '1.85rem', letterSpacing: '0.1rem' }}>
					B<Spinner animation='grow' style={{ color: '#ff0000' }} />{' '}
					<Spinner animation='grow' style={{ color: '#ff0000' }} />
					tstrapping...
				</span>
			</div>
		</AbsoluteLayout>
	);
};

AppLoader.propTypes = {
	visible: PropTypes.bool.isRequired
};

export default AppLoader;
export { AppLoader };
