import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	branchNewsResponse: null
});

export default function branchNewsReducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.BRANCH_NEWS_SET: {
			return Immutable.set(state, 'branchNewsResponse', payload.branchNews);
		}
		default: {
			return state;
		}
	}
}
