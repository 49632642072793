import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Auth]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	LOGIN: `${prefix} login`,
	LOGOUT: `${prefix} logout`,
	REGISTER: `${prefix} register`,
	RESETPASSWORD: `${prefix} resetpassword`,
	RESETPASSWORD2: `${prefix} resetpassword2`,
	CONFIRM_EMAIL: `${prefix} confirm_email`,
	CHANGE_EMAIL_LOADING_SET: `${prefix} change_email_loading_set`,
	UPDATE: `${prefix} update`,

	PROFILE_RELOAD: `${prefix} profile-reload`,
	PROFILE_REFRESH: `${prefix} profile-refresh`
};

const actions = {
	...TYPES,
	login: makeActionCreator(TYPES.LOGIN, 'credentials'),
	logout: makeActionCreator(TYPES.LOGOUT),
	register: makeActionCreator(TYPES.REGISTER, 'credentials'),
	resetpassword: makeActionCreator(TYPES.RESETPASSWORD, 'credentials'),
	resetpassword2: makeActionCreator(TYPES.RESETPASSWORD2, 'credentials'),
	confirmEmail: makeActionCreator(TYPES.CONFIRM_EMAIL, 'credentials'),
	setChangeEmailLoading: makeActionCreator(TYPES.CHANGE_EMAIL_LOADING_SET, 'isChangeEmailLoading'),
	update: makeActionCreator(TYPES.UPDATE, 'credentials', 'data', 'options'),

	profileReload: makeActionCreator(TYPES.PROFILE_RELOAD),
	profileRefresh: makeActionCreator(TYPES.PROFILE_REFRESH, 'user')
};

export default actions;
export { actions as authActions };
