import styled from 'styled-components';

import { THEME } from '../../constants/theme';

const { bg, height, text } = THEME;

export const Footer = styled.footer`
  min-height: ${height.footer}px;
  background-color: #e9e9e9;
  color: #505258;
  /*color: ${text.invert};
  background-color: ${bg.header};*/
`;
