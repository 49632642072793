import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Projects]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	PROJECTS_LOAD: `${prefix} projects_load`,
	PROJECTS_SET: `${prefix} projects_set`
};

const actions = {
	...TYPES,
	setProjects: makeActionCreator(TYPES.PROJECTS_SET, 'projects'),
	loadProjects: makeActionCreator(TYPES.PROJECTS_LOAD, 'query')
};

export default actions;
export { actions as projectsAction };
