import Immutable from 'seamless-immutable';

import actions from './actions';

const initState = Immutable.from({
	page4: null
});

export default function page4Reducer(state = initState, { type, payload }) {
	switch (type) {
		case actions.PAGE4_SET: {
			return Immutable.set(state, 'page4', payload.page4);
		}
		default: {
			return state;
		}
	}
}
