import { ReduxUtils } from '../../utils/ReduxUtils';

const prefix = '[Footer Static Pages]';
const { makeActionCreator } = ReduxUtils;

const TYPES = {
	STATIC_PAGES_LOAD: `${prefix} static_pages_load`,
	STATIC_PAGES_SET: `${prefix} static_pages_set`
};

const actions = {
	...TYPES,
	loadStaticPages: makeActionCreator(TYPES.STATIC_PAGES_LOAD),
	setStaticPages: makeActionCreator(TYPES.STATIC_PAGES_SET, 'staticPages')
};

export default actions;
export { actions as staticPagesAction };
